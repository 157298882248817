import { ComboboxItem, Grid, Select, Textarea, Title, Text } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { WayOfPayment } from '../../../../models/WayOfPayment';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { InputAccountNumber } from './InputAccountNumber';
import { InputBgPg } from './InputBgPg';
import { InputClearingNumber } from './InputClearingNumber';
import { ViewerWayOfPayment } from './ViewerWayOfPaymentElement';
import { WayOfPaymentElementValue } from './WayOfPaymentValue';
import { defaultWayOfPayments } from '../DefaultElements';
import { isMissing } from 'utilitype';
import LanguageHandler from '../../../../utils/languageHandler';
import { LanguageCode } from '../../../../models/LanguageCode';

export type FormElementWayOfPayment = {
  allowedPaymentMethods: WayOfPayment[];
} & FormElement;

type WayOfPaymentElementProps = {
  element: FormElementWayOfPayment;
} & FormElementComponentProps;

export const WayOfPaymentElement: React.FC<WayOfPaymentElementProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {

  const wayOfPaymentLabels = [
    { value: WayOfPayment.BankAccount, label: t`Bankkonto` },
    { value: WayOfPayment.PlusGiro, label: t`PlusGiro` },
    { value: WayOfPayment.BankGiro, label: t`BankGiro` },
    { value: WayOfPayment.Other, label: t`Annat` },
  ];

  if (mode === FormElementMode.Viewer) {
    return <ViewerWayOfPayment number={number} element={element} />;
  }

  const { description } = element;
  const allowedWayOfPayments = element.allowedPaymentMethods ?? defaultWayOfPayments;

  const value = element.value as WayOfPaymentElementValue;

  const { wayOfPayment, bgNumber, pgNumber, clearingNumber, accountNumber, otherValue } = value;

  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);

  const getWayOfPayments = (): ComboboxItem[] => {
    return wayOfPaymentLabels.reduce<ComboboxItem[]>((resultList, wop) => {
      if (allowedWayOfPayments.some(x => x === wop.value)) {
        return [...resultList, { value: wop.value.toString(), label: wop.label }];
      }
      return resultList;
    }, []);
  };

  return (
    <Grid gutter={'xs'}>
      <Grid.Col span={12}>
        <Title order={4}>{t`Utbetalningsinformation`}</Title>
        {!isMissing(descriptionText) && (
          <Text size={'xs'} c={'dimmed'}>
            {descriptionText}
          </Text>
        )}
      </Grid.Col>

      <Grid.Col span={{ lg: 4 }}>
        <Select
          label="Typ"
          error={error}
          required
          value={wayOfPayment.toString()}
          placeholder={t`Utbetalningssätt`}
          disabled={mode === FormElementMode.Editor}
          data={getWayOfPayments()}
          onChange={(event) => {
            if (onChange) {
              onChange({
                wayOfPayment: Number(event),
                pgNumber: '',
                bgNumber: '',
                clearingNumber: '',
                accountNumber: '',
              });
            }
          }}
        />
      </Grid.Col>
      {wayOfPayment === WayOfPayment.BankAccount && (
        <>
          <Grid.Col span={{ lg: 4 }}>
            <InputClearingNumber
              label={t`Clearingnummer`}
              error={!!error}
              value={clearingNumber}
              onChange={(newClearingNumber) => {
                if (onChange) {
                  onChange({ ...value, clearingNumber: newClearingNumber });
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ lg: 4 }}>
            <InputAccountNumber
              label={t`Kontonummer`}
              error={!!error}
              value={accountNumber}
              onChange={(newAccountNumber) => {
                if (onChange) {
                  onChange({ ...value, accountNumber: newAccountNumber });
                }
              }}
            />
          </Grid.Col>
        </>
      )}
      {(wayOfPayment === WayOfPayment.PlusGiro || wayOfPayment === WayOfPayment.BankGiro) && (
        <Grid.Col span={{ lg: 8 }}>
          <InputBgPg
            error={!!error}
            wayOfPayment={wayOfPayment}
            value={wayOfPayment === WayOfPayment.PlusGiro ? pgNumber : bgNumber}
            onChange={(newValue) => {
              if (onChange) {
                if (wayOfPayment === WayOfPayment.PlusGiro) {
                  onChange({ ...value, pgNumber: newValue });
                } else {
                  onChange({ ...value, bgNumber: newValue });
                }
              }
            }}
          />
        </Grid.Col>
      )}
      {wayOfPayment === WayOfPayment.Other && (
        <Grid.Col span={{ lg: 8 }}>
          <Textarea
            error={!!error}
            onChange={(event) => {
              if (onChange) {
                onChange({ ...value, otherValue: event.target.value });
              }
            }}
            label={t`Kontoinformation`}
            value={otherValue}
            minRows={2}
            maxRows={4}
            maxLength={1024}
            autosize
          />
        </Grid.Col>
      )}
    </Grid>
  );
};
