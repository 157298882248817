import { DefaultMantineColor, MantineTheme } from '@mantine/core';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import { t } from 'ttag';

export interface StatusInformation {
  title: string;
  color: string;
  colorKey: DefaultMantineColor;
}

export const ApplicationStatuses = (
  theme: MantineTheme,
): Record<ApplicationStatus, StatusInformation> => {
  return {
    [ApplicationStatus.Approved]: {
      title: t`Beviljad`,
      color: theme.colors.green[6],
      colorKey: 'green.6',
    },
    [ApplicationStatus.Declined]: {
      title: t`Nekad`,
      color: theme.colors.red[6],
      colorKey: 'red.6',
    },
    [ApplicationStatus.Draft]: {
      title: t`Pågående`,
      color: theme.colors.yellow[5],
      colorKey: 'yellow.5',
    },
    [ApplicationStatus.Submitted]: {
      title: t`Inlämnad`,
      color: theme.colors.gray[6],
      colorKey: 'gray.6',
    },       
    // [ApplicationStatus.SubmittedCompletionRequested]: {
    //   title: 'Komplettering begärd',
    //   color: theme.colors.gray[6],
    //   colorKey: 'gray.6',
    // },
    // [ApplicationStatus.SubmittedCompletionResponded]: {
    //   title: 'Inlämnad med komplettering',
    //   color: theme.colors.yellow[5],
    //   colorKey: 'yellow.5',
    // },
  };
};
