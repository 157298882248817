import { Group, Loader, ScrollArea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useStyles } from './Styles';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

interface PDFViewerProps {
  getData: () => Promise<string | null>;
  filename: string;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ getData, filename }) => {
  const [objectUrl, setObjectUrl] = useState<string>('');
  const [pages, setPages] = useState(0);
  const { classes } = useStyles();

  useEffect(() => {
    getData()
      .then((data) => {
        if (data) {
          setObjectUrl(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const loadingUI = (
    <Group align={'center'} pt={'xl'}>
      <Loader size={'xl'} />
    </Group>
  );

  if (!objectUrl) {
    return loadingUI;
  }

  return (
    <div className={classes.pdfWrapper}>
      <ScrollArea style={{ height: '100%' }} pl={'sm'} pb={'md'} pt={'md'} pr={'sm'}>
        <Document
          loading={loadingUI}
          file={objectUrl}
          onLoadSuccess={async (pdf) => {
            setPages(pdf?.numPages ?? 0);
          }}>
          {Array.from({ length: pages }, (_, index) => {
            return (
              <Page
                rotate={0}
                scale={1}
                loading={''}
                width={800}
                key={'pages_' + index}
                pageIndex={index}
                pageNumber={index + 1}
              />
            );
          })}
        </Document>
      </ScrollArea>
    </div>
  );
};
