import React, { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Paper, Space, Title, Input, Stack } from '@mantine/core';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { ApplicationDetails } from '../../models/ApplicationDetails';
import { InteractionType } from '@azure/msal-browser';
import { ApplicantType } from '../../models/ApplicantType';
import { ApplicantFormFields } from '../../components/formbuilder/FormRunner';
import { useForm } from '@mantine/form';
import { RequisitionCompleted } from './RequisitionCompleted';
import { ApplicantViewerOrganization } from './RequisitionComponents/ApplicantViewerOrganization';
import { ApplicantViewerPerson } from './RequisitionComponents/ApplicantViewerPerson';
import { WayPaymentForm } from './RequisitionComponents/WayOfPaymentForm';
import { t } from 'ttag';

export const RequisitionView: React.FC = () => {
  const { applicationId } = useParams();
  const organisation = useOrganisationContext();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const [application, setApplication] = useState<ApplicationDetails>();
  const form = useForm<ApplicantFormFields>();
  const [payments, setPayments] = useState<any[]>();
  const [isCompleted, setIsCompleted] = useState<boolean>();

  useEffect(() => {
    const fetchData = async () => {
      if (organisation && applicationId) {
        const applicationResponse = await api.getApplication(organisation.id, applicationId);

        form.setValues(applicationResponse);
        setApplication(applicationResponse);

        const paymentsResponse = await api.getPayments(organisation.id, applicationId);
        setPayments(paymentsResponse);
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [organisation, isAuthenticated]);

  const claimPayment = async () => {
    {
      /* 
    TODO: I framtiden kommer vi ha stöd för flerårsanslag. 
    Det kan då finnas flera payments, både de som redan är betalda och de som ska betalas ut i framtiden. 
    */
    }

    if (!organisation || !application || !payments) {
      return null;
    }

    const response = await api.claimPayment(organisation.id, application.id, {
      paymentId: payments[0].id,
      message: '',
      wayOfPayment: form.values.wayOfPayment,
      bgNumber: form.values.bgNumber,
      pgNumber: form.values.pgNumber,
      clearingNumber: form.values.clearingNumber,
      accountNumber: form.values.accountNumber,
    });

    if (response.ok) {
      setIsCompleted(true);
    }
  };

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <GrantMeContainer>
        {organisation && application && payments && (
          <Stack>
            {isCompleted && <RequisitionCompleted />}
            {!isCompleted && (
              <>
                <Title order={4}>{t`Rekvirera ansökan ${application.number}`}</Title>
                <Paper shadow="sm" p="xl" withBorder>
                  {application.applicantType === ApplicantType.Person ? (
                    <ApplicantViewerPerson application={application} />
                  ) : (
                    <ApplicantViewerOrganization application={application} />
                  )}
                </Paper>

                <form onSubmit={form.onSubmit(() => claimPayment())}>
                  <Paper shadow="sm" p="xl" withBorder>
                    <Grid>
                      <Grid.Col span={{ lg: 12 }}>
                        <WayPaymentForm form={form} showViewer={false} />
                      </Grid.Col>

                      <Grid.Col span={{ lg: 12 }}>
                        <Input.Wrapper label={t`Belopp att rekvirera`}>
                          <br />
                          {/* 
                      TODO: I framtiden kommer vi ha stöd för flerårsanslag. 
                      Det kan då finnas flera payments, både de som redan är betalda och de som ska betalas ut i framtiden. 
                      */}
                          {payments[0].amount} SEK
                        </Input.Wrapper>
                      </Grid.Col>
                      <Grid.Col span={{ lg: 12 }}>
                        <Button type="submit">{t`Rekvirera`}</Button>
                      </Grid.Col>
                    </Grid>
                  </Paper>
                  <Space h={'md'} />
                </form>
              </>
            )}
          </Stack>
        )}
      </GrantMeContainer>
    </MsalAuthenticationTemplate>
  );
};
