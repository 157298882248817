import { Text, Group, Container, Anchor } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { SEFlag, GBFlag } from 'mantine-flagpack';
import { getLocale, setLocale } from '../i18nInit';
import { createStyles } from '@mantine/emotion';
import { t } from 'ttag';

const useStyles = createStyles((theme, _, u) => ({
  footer: {
    marginTop: 24,
    paddingTop: 24,
    paddingBottom: 24,
    background: theme.colors.gray[2],
    borderTop: `1px solid ${
      theme.colors.gray[3]
    }`,
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [u.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const toggleLocale = () => {
  if (getLocale() === 'sv') {
    setLocale('en');
  } else {
    setLocale('sv');
  }

  window.location.reload();
};

export const Footer = () => {
  const { classes, theme } = useStyles();

  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  return (
    <footer className={classes.footer}>
      <Container size="lg">
        <Group align={isSmallScreen ? 'center' : 'apart'} w={'100%'}>
          {!isSmallScreen && (
            <Text color="dimmed" size="sm">
              © {new Date().getFullYear()} GRANTME
            </Text>
          )}
          <Group gap={'xl'} align="right">
            <Anchor onClick={() => toggleLocale()}>
              <Group gap={'xs'}>
                {getLocale() === 'en' && (
                  <>
                    <SEFlag style={{ width: 16 }} radius={'xs'} title={t`Svenska`} />
                    <Text c="dimmed" size="sm">
                      Svenska
                    </Text>
                  </>
                )}
                {getLocale() === 'sv' && (
                  <>
                    <GBFlag style={{ width: 16 }} radius={'xs'} title={t`Engelska`} />
                    <Text c="dimmed" size="sm">
                      English
                    </Text>
                  </>
                )}
              </Group>
            </Anchor>
            <img width="24" src="/logo192.png" />
          </Group>
        </Group>
      </Container>
    </footer>
  );
};
