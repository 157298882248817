import { Menu, ActionIcon } from '@mantine/core';
import { getLocale, setLocale } from '../i18nInit';
import { GBFlag, SEFlag } from 'mantine-flagpack';
import { t } from 'ttag';

export const NavbarLocaleSwitcher = () => {
  const toggleLocale = () => {
    if (getLocale() === 'sv') {
      setLocale('en');
    } else {
      setLocale('sv');
    }

    window.location.reload();
  };

  if (getLocale() === 'sv') {
    return (
      <Menu.Target>
        <ActionIcon onClick={() => toggleLocale()} variant="subtle">
          <GBFlag style={{ width: '18px' }} radius={'xs'} title={t`Engelska`} />
        </ActionIcon>
      </Menu.Target>
    );
  }

  return (
    <Menu.Target>
      <ActionIcon onClick={() => toggleLocale()} variant="subtle">
        <SEFlag style={{ width: '18px' }} radius={'xs'} title={t`Svenska`} />
      </ActionIcon>
    </Menu.Target>
  );
};
