import { FC, useEffect, useState } from 'react';
import { AppShell, useMantineTheme } from '@mantine/core';
import { Outlet, useParams } from 'react-router-dom';
import { Organisation } from '../models/Organisation';
import { OrganisationContext } from '../hooks/useOrganisation';
import { MenuHeader } from './MenuHeader';
import { ContentContainer } from './ContentContainer';
import { useApi } from '../hooks/useApi';
import { t } from 'ttag';
import { ModalsProvider } from '@mantine/modals';
import { useIsAuthenticated } from '@azure/msal-react';
import { showImportantNotification } from '../utils/notificationHelper';
import '@mantine/core/styles.css';

import { Notifications } from '@mantine/notifications';

export const HEADER_HEIGHT = 84;

type LayoutProps = {
  setCustomColor: React.Dispatch<React.SetStateAction<string>>;
};

export const Layout: FC<LayoutProps> = ({ setCustomColor }) => {
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const { organisationSlug } = useParams();
  const api = useApi();

  console.log('LAYOUT SLUG: ', organisationSlug);

  const links = [
    {
      label: t`Start`,
      link: `/${organisationSlug}/applicationperiods`,
      requireAuthentication: false,
    },
    {
      label: t`Ansökningar`,
      link: `/${organisationSlug}/applications`,
      requireAuthentication: true,
    },
    {
      label: t`Min sida`,
      link: `/${organisationSlug}/profile`,
      requireAuthentication: true,
    },
  ];

  const theme = useMantineTheme();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fetchData = async () => {
      if (organisationSlug) {
        const result = await api.getOrganisation(organisationSlug);
        if (result) {
          setOrganisation(result);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated && organisation) {
        const result = await api.getNotifications(organisation.id);
        if (result && result.hasApplicationReports) {
          showImportantNotification(
            t`Viktigt!`,
            t`Du har återrapporter som måste lämnas in. Klicka på "Ansökningar" för mer information.`,
          );
        } else if (result && result.hasCompletionRequests) {
          showImportantNotification(
            t`Viktigt!`,
            t`Du har en komplettering som behöver lämnas in. Klicka på "Ansökningar" för mer information.`,
          );
        } else if (result && result.hasRequisitions) {
          showImportantNotification(
            t`Viktigt!`,
            t`Du har en rekvirering som kan göras. Klicka på "Ansökningar" för mer information.`,
          );
        }
      }
    };

    if (organisation) {
      setCustomColor(organisation.primaryColor);
    }

    if (isAuthenticated && organisation) {
      fetchData();
    }
  }, [isAuthenticated, organisation]);

  return (
    <OrganisationContext.Provider value={organisation}>
      <ModalsProvider>
        <AppShell
          header={{ height: 84 }}
          styles={{
            root: {
              overflow: 'hidden',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            },
            main: {
              background: theme.colors.gray[0],
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0,
              flex: 1,
              overflow: 'hidden',
            },
          }}>
          <AppShell.Header>
            <MenuHeader mainLinks={links} />
          </AppShell.Header>
          <AppShell.Main>
            <ContentContainer>
              <Outlet />
            </ContentContainer>
          </AppShell.Main>
        </AppShell>
        <Notifications position="top-center"></Notifications>
      </ModalsProvider>
    </OrganisationContext.Provider>
  );
};
