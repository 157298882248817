import React, { FC, useEffect, useState } from 'react';
import {
  Title,
  Stack,
  Badge,
  Text,
  Accordion,
  Group,
  Divider,
  Grid,
  Button,
  Alert,
  Space,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { Application, ApplicationType } from '../../models/Application';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import { t } from 'ttag';
import { openConfirmModal } from '@mantine/modals';
import { CompletionStatus } from '../../models/CompletionStatus';
import Formatter from '../../utils/formatter';
import { ApplicationStatuses } from './ApplicationStatuses';
import { createStyles } from '@mantine/emotion';
import { IconAlertCircle } from '@tabler/icons-react';

const useStyles = createStyles(() => ({
  rowHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  applicationItem: {
    flex: 1,
    padding: '8px 16px',
  },
  gridColButtons: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
}));

export const ApplicationList: FC = () => {
  const api = useApi();
  const organisation = useOrganisationContext();
  const [applications, setApplications] = useState<Application[]>();
  const navigate = useNavigate();
  const { organisationSlug } = useParams();
  const { classes, theme } = useStyles();

  const applicationStatuses = ApplicationStatuses(theme);

  const refreshApplications = async () => {
    if (organisation) {
      const response = await api.getApplications(organisation.id);
      setApplications(response);
    }
  };

  useEffect(() => {
    if (organisation) {
      const fetchData = async () => {
        await refreshApplications();
      };

      fetchData();
    }
  }, [organisation]);

  if (!organisation || !applications) {
    return <div />;
  }

  const deleteApplication = async (id: string) => {
    await api.deleteApplication(organisation.id, id);
    await refreshApplications();
  };

  const deleteApplicationModal = (id: string) => {
    return openConfirmModal({
      title: <Title order={4}>{t`Ta bort ansökan`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>{t`Är du säker på att du vill ta bort den påbörjade ansökan?`}</Text>
        </Stack>
      ),
      labels: { confirm: t`Ta bort`, cancel: t`Avbryt` },
      onCancel: () => console.log('close'),
      onConfirm: () => deleteApplication(id),
    });
  };

  const getBadge = (count: number) => {
    return (
      <Badge
        style={{ width: 20, height: 20, pointerEvents: 'none' }}
        variant="filled"
        size="sm"
        p={0}>
        {count}
      </Badge>
    );
  };

  const getApplicationTitle = (
    number: string | undefined,
    title: string | undefined,
    periodTitle: string,
  ) => {
    if (title && number) {
      return (
        <Stack gap={0}>
          <Text size={'sm'} c="dimmed">
            {periodTitle}
          </Text>
          <Text size={'sm'}>
            {number} {title}
          </Text>
        </Stack>
      );
    } else if (number) {
      return (
        <Text size={'sm'}>
          {number} {periodTitle}
        </Text>
      );
    } else {
      return <Text size={'sm'}>{periodTitle}</Text>;
    }
  };

  const ongoingApplications = applications.filter(
    (x) =>
      x.type === ApplicationType.Application &&
      x.status === ApplicationStatus.Draft &&
      x.completionStatus === CompletionStatus.None,
  );

  const requisitionApplications = applications.filter(
    (x) => x.type === ApplicationType.Application && x.hasClaimablePayments,
  );

  const submittedApplications = applications.filter(
    (x) =>
      (x.type === ApplicationType.Application &&
        (x.status === ApplicationStatus.Submitted ||
          x.status === ApplicationStatus.Approved ||
          x.status === ApplicationStatus.Declined) &&
        x.completionStatus !== CompletionStatus.Requested) ||
      (x.type === ApplicationType.Report && x.status !== ApplicationStatus.Draft),
  );

  const completionRequestedApplications = applications.filter(
    (x) =>
      x.status === ApplicationStatus.Submitted && x.completionStatus === CompletionStatus.Requested,
  );

  const applicationReports = applications.filter(
    (x) => x.type == ApplicationType.Report && x.status == ApplicationStatus.Draft,
  );

  return (
    <GrantMeContainer>
      <Stack>
        {applicationReports.map((x) => (
          <Alert
            key={x.id}
            icon={<IconAlertCircle size={32} />}
            title={t`Lämna in återrapport`}
            variant="filled">
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 9 }}>
                {t`Du har en återrapport som ska lämnas in för ansökan`}{' '}
                <b>
                  {x.parentApplicationNumber} {x.parentApplicationTitle}
                </b>
                . {t`Sista datum för återrapportering är`}{' '}
                <b>{Formatter.formatDateString(x.reportingDueDate)}</b>.
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 3 }}>
                <Group justify="right">
                  <Button variant="white" onClick={() => navigate(`${x.id}/edit`)}>
                    {t`Lämna in återrapport`}
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Alert>
        ))}

        {completionRequestedApplications.map((x) => (
          <Alert
            key={x.id}
            icon={<IconAlertCircle size={32} />}
            title={t`Lämna in komplettering`}
            variant="filled">
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 9 }}>
                {t`Du har fått en begäran om komplettering för ansökan`}{' '}
                <b>
                  {x.number} {x.title}
                </b>
                .
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 3 }}>
                <Group justify="right">
                  <Button variant="white" onClick={() => navigate(`${x.id}/edit`)}>
                    {t`Lämna in komplettering`}
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Alert>
        ))}

        {requisitionApplications.map((x) => (
          <Alert
            key={x.id}
            icon={<IconAlertCircle size={32} />}
            title={t`Rekvirering`}
            variant="filled">
            <Grid>
            <Grid.Col span={{ xs: 12, sm: 9 }}>
                {t`Du kan nu göra en rekvirering för ansökan`}{' '}
                <b>
                  {x.number} {x.title}
                </b>
                .
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 3 }}>
                <Group justify="right">
                  <Button variant="white" onClick={() => navigate(`${x.id}/requisition`)}>
                    {t`Rekvirera`}
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Alert>
        ))}

        <Accordion
          variant={'contained'}
          defaultValue={
            ongoingApplications.length > 0
              ? 'ongoing'
              : submittedApplications.length > 0
                ? 'submitted'
                : ''
          }
          styles={(aTheme) => ({
            panel: {
              backgroundColor: aTheme.white,
            },
            control: {
              backgroundColor: aTheme.white,
            },
          })}>
          <Accordion.Item value={'ongoing'} color='red'>
            <Accordion.Control pl={'sm'}>
              <Group>
                <Text size={'md'} fw={600}>{t`Påbörjade ansökningar`}</Text>
                {getBadge(ongoingApplications.length)}
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              {ongoingApplications.length === 0 && (
                <Group justify={'center'} p={'xl'}>
                  <Text size={'sm'}>{t`Du har inga påbörjade ansökningar.`}</Text>
                </Group>
              )}

              {ongoingApplications.map((app, index) => (
                <React.Fragment key={app.id}>
                  <Grid p={'sm'}>
                    <Grid.Col span={{ xs: 12, sm: 9 }}>
                      {getApplicationTitle(app.number, app.title, app.applicationPeriodTitle)}
                      <Space h="xs" />
                      <Badge variant="outline" size="md" color={'gray.6'}>
                        {' '}
                        {t`Ändrad`} {Formatter.formatDateString(app.modifiedUtc)}
                      </Badge>
                    </Grid.Col>

                    <Grid.Col className={classes.gridColButtons} span={{ xs: 12, sm: 3 }}>
                      <Group justify={'end'} align={'center'}>
                        <Button size="xs"
                          onClick={() =>
                            navigate(`/${organisationSlug}/applications/${app.id}/edit`)
                          }>
                          {t`Redigera`}
                        </Button>
                        <Button size='xs' onClick={() => deleteApplicationModal(app.id)}>{t`Ta bort`}</Button>
                      </Group>
                    </Grid.Col>
                  </Grid>

                  {index < ongoingApplications.length - 1 && <Divider color={'gray.2'} p={0} />}
                </React.Fragment>
              ))}
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value={'submitted'}>
            <Accordion.Control pl={'sm'}>
              <Group>
                <Text size={'md'} fw={600}>{t`Inlämnade ansökningar`}</Text>
                {getBadge(submittedApplications.length)}
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              {submittedApplications.length === 0 && (
                <Group justify={'center'} p={'xl'}>
                  <Text size={'sm'}>{t`Du har inga inlämnade ansökningar.`}</Text>
                </Group>
              )}

              {submittedApplications.map((app, index) => (
                <React.Fragment key={app.id}>
                  <Grid p={'sm'}>
                    <Grid.Col span={{ xs: 6, sm: 10 }}>
                      {getApplicationTitle(app.number, app.title, app.applicationPeriodTitle)}
                      <Space h="xs" />
                      <Badge
                        variant="outline"
                        size="md"
                        color={applicationStatuses[app.status].colorKey}>
                        {applicationStatuses[app.status].title}{' '}
                        {Formatter.formatDateString(app.modifiedUtc)}
                      </Badge>
                    </Grid.Col>

                    <Grid.Col className={classes.gridColButtons} span={{ xs: 12, sm: 2 }}>
                      <Group justify="center">
                        <Button
                        size='xs'
                          variant="filled"
                          onClick={() => navigate(`/${organisationSlug}/applications/${app.id}`)}>
                          {app.type === ApplicationType.Application && <span>{t`Visa ansökan`}</span>}
                          {app.type === ApplicationType.Report && <span>{t`Visa återrapport`}</span>}
                        </Button>
                      </Group>
                    </Grid.Col>
                  </Grid>

                  {index < submittedApplications.length - 1 && <Divider color={'gray.2'} p={0} />}
                </React.Fragment>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
    </GrantMeContainer>
  );
};
