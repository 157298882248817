import React from 'react';
import { TypographyStylesProvider } from '@mantine/core';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElement } from '../../models/FormElement';
import { FormElementMode } from '../../FormElementMode';

type ParagraphProps = {
  element: FormElement;
} & FormElementComponentProps;

export const Paragraph: React.FC<ParagraphProps> = ({ element, mode }) => {
  const text = element.value?.toString() ?? '';

  return (
    <TypographyStylesProvider fz={'sm'} mih={16} pt={mode === FormElementMode.Editor ? 'lg' : ''}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </TypographyStylesProvider>    

  );
};
