import { FC, useEffect, useState } from 'react';
import {
  Title,
  Stack,
  Grid,
  Space,
  useMantineTheme,
  TypographyStylesProvider,
} from '@mantine/core';
import { ApplicationPeriod, ApplicationPeriodStatus } from '../../models/ApplicationPeriod';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { ApplicationPeriodCard } from './application-period-card';
import { t } from 'ttag';
import LanguageHandler from '../../utils/languageHandler';
import { getLocale } from '../../i18nInit';
import { LanguageCode } from '../../models/LanguageCode';

export const ApplicationPeriodsView: FC = () => {
  const [applicationPeriods, setApplicationPeriods] = useState<ApplicationPeriod[]>([]);
  const organisation = useOrganisationContext();
  const api = useApi();
  const theme = useMantineTheme();

  useEffect(() => {
    if (organisation) {
      const fetchData = async () => {
        const result = await api.getApplicationPeriods(organisation.id);
        setApplicationPeriods(result);
      };

      fetchData();
    }
  }, [organisation]);

  if (!organisation) {
    return <div />;
  }

  const activeApplicationPeriods: ApplicationPeriod[] =
    applicationPeriods?.filter((period) => period.status === ApplicationPeriodStatus.Ongoing) ?? [];
  const upcomingApplicationPeriods =
    applicationPeriods?.filter((period) => period.status == ApplicationPeriodStatus.Upcoming) ?? [];

  //const maxItems = Math.max(activeApplicationPeriods.length, upcomingApplicationPeriods.length);
  const colSpan = 12; //maxItems >= 3 ? 4 : maxItems === 2 ? 6 : 12;
  const addSpace = activeApplicationPeriods.length > 0 && upcomingApplicationPeriods.length > 0;

  return (
    <>
      {organisation?.descriptions && (
        <div style={{ minHeight: 300, background: theme.colors[theme.primaryColor][7] }}>
          <GrantMeContainer>
            <TypographyStylesProvider style={{ color: organisation?.fontColor ?? theme.white }}>
              <div dangerouslySetInnerHTML={{ __html: LanguageHandler.getTextByLanguage(organisation?.descriptions, LanguageCode[getLocale()!]) ?? '' }} />
            </TypographyStylesProvider>
            <Space h={'md'} />
          </GrantMeContainer>
        </div>
      )}

      <GrantMeContainer>
        <Stack>
          {activeApplicationPeriods.length > 0 && (
            <>
              <Title order={3}>{t`Pågående ansökningsperioder`}</Title>
              <Grid>
                {activeApplicationPeriods.map((grant) => (
                  <Grid.Col key={grant.id} span={{ lg: colSpan, md: colSpan === 12 ? colSpan : 6 }}>
                    <ApplicationPeriodCard
                      grant={grant}
                      organisation={organisation}
                      isUpcoming={false}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </>
          )}
          {addSpace && <Space h={'xl'} />}
          {upcomingApplicationPeriods.length > 0 && (
            <>
              <Title order={3}>{t`Kommande ansökningsperioder`}</Title>
              <Grid>
                {upcomingApplicationPeriods.map((grant) => (
                  <Grid.Col key={grant.id} span={{ lg: colSpan, md: colSpan === 12 ? colSpan : 6 }}>
                    <ApplicationPeriodCard
                      grant={grant}
                      organisation={organisation}
                      isUpcoming={true}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </>
          )}
        </Stack>
      </GrantMeContainer>
    </>
  );
};
